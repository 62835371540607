export const queryFragmentSingleTypeDefault = {
  titles: {
    fields: [
      'i18n_string_en',
      'i18n_string_nl',
    ]
  },
  introductions: {
    fields: [
      'i18n_richtext_en',
      'i18n_richtext_nl',
    ]
  }
}
